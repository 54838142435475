import React from "react";
import PropTypes from "prop-types";

const Card = (props) => {
  return (
    <>
      {/* card desigen start */}
      <>
        <div className="group shadow-md hover:bg-red-500 hover:ring-pink-500 p-6 rounded-md bg-black">
          <div
            className="p-4 border-slate-800 mb-3 text-red-600 group-hover:text-white flex items-center "
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "3rem",
            }}
          >
            {/* use props icon */}
            {props.icon}
          </div>
          <h3 className="text-2xl font-bold text-gray-100 mb-3 text-center group-hover:text-white text-ellipsis">
            {/* use props title*/}
            {props.title}
          </h3>
          <p className="text-lg text-gray-400 text-center group-hover:text-white text-ellipsis">
            {/* use props description */}
            {props.description}
          </p>
        </div>
      </>
      {/* card desigen end */}
    </>
  );
};

//  props type start
Card.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
};
// props type end

export default Card;
