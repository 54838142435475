import React from "react";
import pic from "../../static/about.png";

const About = () => {
  return (
    <>
      {/* about section start */}
      <section
        className="text-gray-600 body-font"
        style={{ backgroundColor: "#18181b" }}
      >
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={pic}
            />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 text-gray-200 font-bold">
              About Us
            </h1>
            <p className="mb-8 leading-relaxed text-gray-200">
              At <strong className="text-green-500 text-xl">KhandakerIT</strong>
              , we believe in the power of technology to transform businesses.
              With over a decade of experience, we specialize in crafting
              bespoke web, mobile, and desktop solutions that drive success. Our
              team of experts combines innovation with functionality to create
              products that not only meet but exceed our clients expectations.
            </p>
            <div className="justify-center inline-flex">
              <div className="text-gray-200 border-0 py-2 px-6 rounded">
                <h2 className="text-xl font-bold">Our Mission</h2>
                <p>
                  To deliver cutting-edge software solutions that empower
                  businesses to achieve their goals, while setting the benchmark
                  for quality and innovation in the industry.
                </p>
              </div>
              <div className="text-gray-200 border-0 py-2 px-6 rounded">
                <h2 className="text-xl font-bold">Our Values</h2>
                <p>
                  Integrity, innovation, and customer success guide us in every
                  project we undertake, ensuring we deliver value and excellence
                  in all our engagements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* about section start */}
    </>
  );
};

export default About;
