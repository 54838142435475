import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { BsGithub } from "react-icons/bs";
import footerImage from "../../static/footer_image/footer1.png";

const Footer = () => {
  return (
    <>
      {/* footer start */}
      <footer className="bg-black">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 ">
          {/* image section */}
          <div
            style={{ backgroundImage: `url(${footerImage})` }}
            className="w-full h-full 2xl:py-[3rem] bg-cover bg-no-repeat rounded "
          >
            <div className="px-16 py-[3rem] sm:px-24 md:px-[9rem] md:py-[4rem] ">
              <h5 className="text-white 2xl:text-lg">READY TO DO THIS</h5>
              <h2 className="text-white font-bold text-[2.5rem] sm:text-[3.5rem] 2xl:text-[4rem]">
                Lets get
                <br />
                to work
              </h2>
              <Link to="/contact">
                <button
                  className="inline-flex items-center bg-white border-1 py-2 px-4 sm:px-5 sm:py-3 md:px-7 2xl:px-11 2xl:py-4 2xl:pt-4 focus:outline-none
                 hover:bg-white hover:text-red-600 rounded text-base mt-4 md:mt-2 lg:mt-5 2xl:mt-4
                 bg-transparent text-slate-300 font-semibold border border-white hover:border-transparent"
                >
                  CONTACT US
                </button>
              </Link>
            </div>
          </div>
          {/* image section end */}

          {/* footer section part start */}
          <div className="bg-black w-[100%] h-[28rem] sm:h-[17rem] lg:pt-[6.2rem] xl:pt-[4.1rem] 2xl:pt-[6.6rem]">
            <div
              className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 px-12 py-16 sm:px-16 md:pr-4 xl:px-7 2xl:p-[6rem]"
              style={{ backgroundColor: "#18181b" }}
            >
              <div className="sm:text-s">
                <p className="font-semibold text-white text-xl md:text-2xl 2xl:text-4xl">
                  Quick Link
                </p>
                <nav
                  aria-label="Footer Nav"
                  className="mt-4 flex flex-col space-y-3 text-sm xl:text-xl 2xl:text-2xl text-gray-300"
                >
                  <a
                    className="hover:opacity-75 hover:text-red-600"
                    href="/about"
                  >
                    About us
                  </a>
                  <a
                    className="hover:opacity-75 hover:text-red-600"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.amgoai.com"
                  >
                    Visit AMGOAI
                  </a>
                  <a
                    className="hover:opacity-75 hover:text-red-600"
                    href="https://www.amgoai.com/news"
                  >
                    Our news
                  </a>
                </nav>
              </div>
              <div>
                <p className="font-semibold text-white text-xl md:text-2xl 2xl:text-4xl">
                  Say Hello
                </p>

                <nav className="mt-4 flex flex-col space-y-3 text-sm xl:text-xl 2xl:text-2xl text-gray-300">
                  <a
                    className="hover:opacity-75 hover:text-red-600"
                    href={"mailto:contact.khandakerit@gmail.com"}
                  >
                    Free consulting
                  </a>
                  <a
                    className="hover:opacity-75 hover:text-red-600"
                    href={"mailto:contact.khandakerit@gmail.com"}
                  >
                    contact.khandakerit@gmail.com
                  </a>
                  <div className="flex gap-1">
                    <a
                      className="rounded-full border-white/25 p-2 hover:opacity-75"
                      href="https://github.com/ampaking"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsGithub
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "1.8rem",
                        }}
                      />
                    </a>
                    <a
                      className="rounded-full border-white/25 p-2 hover:opacity-75"
                      href="https://github.com/khandakerit"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsGithub
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "1.8rem",
                        }}
                      />
                    </a>
                  </div>
                </nav>
                {/* footer section part end */}
              </div>
            </div>
          </div>
        </div>
        {/* Scrollbar Start */}
        <div>
          <ScrollToTop
            smooth
            component={<p className="text-pink-700 text-2xl pt-2">⌃</p>}
          />
        </div>
        {/* Scrollbar end */}
      </footer>
      {/* footer end */}
    </>
  );
};

export default Footer;
