import React, { useEffect, useState } from "react";
import axios from "axios";
import LittleBanner from "../../Component/little_banner/LittleBanner";
import ContactUs from "../../Component/contact_section/ContactUS";
import ContactForm from "../../Component/contact_section/ContactForm";
import GoogleMap from "../../Component/google_map/GoogoleMap";
import Brand from "../../Component/brand_section/Brand";
import image from "../../static/contactbanner_image/contactbanner.jpg";
import Navbar from "../../Component/navbar_section/Nevbar";
import Footer from "../../Component/footer_section/Footer";

const ContactPage = () => {
  //  this is  tilte start
  useEffect(() => {
    document.title = "Contact";
  });
  //  this is  tilte end

  // input_felid_state_start
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  // input_felid_state_end
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitMessage, setsubmitMessage] = useState(null);

  //  axios_post_method_connet_start
  const handelSubmit = (e) => {
    setIsSubmit(true);
    e.preventDefault();
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
    axios
      .post(`${process.env.REACT_APP_SECRETE_URL}contact/`, {
        name,
        email,
        phone,
        subject,
        message,
      })

      .then((result) => {
        setIsSubmit(false);
        setsubmitMessage(result.status);
        setTimeout(() => {
          setsubmitMessage(null);
        }, 1500);
      })

      .catch((error) => {
        setsubmitMessage(error.message);
        setIsSubmit(false);
        setTimeout(() => {
          setsubmitMessage(null);
          isSubmit(false);
        }, 5000);
      });
  };
  //  axios_post_method_connet_end

  return (
    <>
      <Navbar />
      <LittleBanner
        image={{ backgroundImage: `url(${image})` }}
        title="Contact Us"
        description="Reach out to us for any inquiries, project discussions, or support needs. Our team is dedicated to providing timely and helpful responses, ensuring your questions are answered and your projects are on the right track. Let's connect and explore how we can collaborate to achieve your goals."
      />
      <ContactUs />
      <div className="bg-black flex items-center justify-center pb-4">
        <a
          target="_"
          href="https://forms.gle/55SxXhf14ivimpGL7"
          className="inline-block rounded border border-indigo-900 bg-indigo-900 px-12 py-3 text-lg font-medium text-white hover:bg-transparent hover:text-indigo-900 focus:outline-none focus:ring active:text-indigo-500"
        >
          <button>
            <div>Share you Project details.</div>
            <div className="text-green-300">Click here</div>
          </button>
        </a>
      </div>
      <div className="bg-black flex items-center justify-center">
        <iframe
          title="contact to k.it"
          src="https://docs.google.com/forms/d/e/1FAIpQLSe4qojBzJtait6WCBHSe-jKpIYBhSM6mUNFOUODLEsjYvaKDg/viewform?embedded=true"
          width="640"
          height="1025"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </div>

      {/* TODO: Reuse below with strong validation */}

      {/* <ContactForm
        handelSubmit={handelSubmit}
        setIsSubmit={setIsSubmit}
        setName={setName}
        setEmail={setEmail}
        setPhone={setPhone}
        setSubject={setSubject}
        setMessage={setMessage}
        isSubmit={isSubmit}
        submitMessage={submitMessage}
        name={name}
        email={email}
        phone={phone}
        subject={subject}
        message={message}
      /> */}
      {/* TODO: add Company location on map after get settle company */}
      {/* <GoogleMap /> */}
      <Brand />
      <Footer />
    </>
  );
};

export default ContactPage;
