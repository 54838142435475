import React from "react";
import PropTypes from "prop-types";

const SkillCard = (props) => {
  return (
    <>
      {/* skill card start */}
      <div className="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30 rounded-lg ">
        <div className="h-[25rem] w-72 lg:h-[30rem] lg:w-[20rem] xl:h-[35rem] xl:w-[25rem] ">
          <img
            className="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
            //  use a props
            src={props.image}
            alt=""
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-red-700/60 group-hover:to-black/70"></div>

        <div className="absolute inset-0 flex translate-y-[60%] py-4 flex-col px-9 transition-all duration-500 group-hover:translate-y-0">
          <div className=" mb-3 text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            <div className="flex gap-1">
              <a
                className=" flex rounded-full border-white/12 p-2 hover:opacity-75 justify-center text-[1.4rem] "
                href="/"
                target="_blank"
                rel="noreferrer"
              >
                {/* use a props */}
                {props.icon1}
              </a>
              <a
                className=" flex rounded-full border-white/12 p-2 hover:opacity-75 justify-center text-[1.4rem]"
                href="/"
                target="_blank"
                rel="noreferrer"
              >
                {/* use a props */}
                {props.icon2}
              </a>
              <a
                className=" flex rounded-full border-white/12 p-2 hover:opacity-75 justify-center text-[1.4rem]"
                href="/"
                target="_blank"
                rel="noreferrer"
              >
                {/* use a props */}
                {props.icon3}
              </a>
            </div>
            <div className="absolute bottom-2 right-2 ">
              <h1 className="font-dmserif text-2xl font-bold text-white ">
                {/* use a props */}
                {props.title}
              </h1>
              <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                {/* use a props */}
                {props.description}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* skill card end */}
    </>
  );
};
// use a propTypes
SkillCard.propTypes = {
  image: PropTypes.any,
  icon1: PropTypes.any,
  icon2: PropTypes.any,
  icon3: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
};
export default SkillCard;
