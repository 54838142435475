import { React, useMemo } from "react";
import {
  BsTelephonePlusFill,
  BsWalletFill,
  BsFillGeoAltFill,
} from "react-icons/bs";
import ContactCard from "./ContactCard";

const ContactUS = () => {
  const contactCard = useMemo(
    () => [
      <ContactCard
        key="1"
        icon={<BsTelephonePlusFill />}
        title="Contact By Phone's"
        description1="🇧🇩 +880 1943909608"
        description2="🇯🇵 +810 9011891199"
      />,
      <ContactCard
        key="2"
        icon={<BsWalletFill />}
        title="Email Address"
        type="email"
        description1="contact.khandakerit@gmail.com"
        description2="contact@khandakerit.com"
      />,
      <ContactCard
        key="3"
        icon={<BsFillGeoAltFill />}
        title="Location"
        description1="🇧🇩 Bangladesh, Dhaka, Khilkhet"
        description2="🇯🇵 Japan, Saitama, Toda"
      />,
    ],
    []
  );
  return (
    <>
      {/* contact start */}
      <section className="bg-black">
        <div className="container mx-auto py-20 px-4 2xl:px-40 ">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-6">
            {/* apply use memo */}
            {contactCard}
          </div>
        </div>
      </section>
      {/* contacts end */}
    </>
  );
};

export default ContactUS;
