import React, { useEffect } from "react";
import image from "../../static/servicebanner.jpg";
import About from "../../Component/about_section/About";
import Count from "../../Component/countup_section/Count";
import Find from "../../Component/find_section/Find";
import Skill from "../../Component/skill_section/Skill";
import Brand from "../../Component/brand_section/Brand";
import LittleBanner from "../../Component/little_banner/LittleBanner";
import Navbar from "../../Component/navbar_section/Nevbar";
import Footer from "../../Component/footer_section/Footer";

const AboutPage = () => {
  //  this is  tilte start
  useEffect(() => {
    document.title = "About";
  });
  //  this is  tilte end
  return (
    <>
      <Navbar />
      <LittleBanner
        title="About"
        image={{ backgroundImage: `url(${image})` }}
        description="Welcome to our story. We are a dynamic team of innovators and problem-solvers dedicated to transforming the digital landscape. With years of experience and a passion for technology, we strive to deliver exceptional solutions that drive success. Our journey is one of continuous learning and improvement, fueled by our commitment to our clients and our love for what we do. Discover who we are and why we're the right partner for your next venture."
      />
      <About />
      <Count />
      <Find />
      <Skill />
      <Brand />
      <Footer />
    </>
  );
};

export default AboutPage;
