import React from "react";
import { Link } from "react-router-dom";
import "../../styles/BannerSection.css";

const BannerSection = () => {
  return (
    <>
      {/* banner_section start */}
      <section className="main">
        <div className="text">
          <h4>WE ARE A DREAMER</h4>
          <h1 className="text1">
            TECHNOLOGY & <br />
            INNOVATION FOR <br />
            YOUR DREAM TO MAKE IT REALITY.
          </h1>
          <Link to="/contact">
            <button
              className="inline-flex items-center bg-pink-700 border-0 py-3 px-7 focus:outline-none
     hover:bg-gray-800 hover:text-white rounded text-base mt-4 md:mt-0"
            >
              CONTACT US
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};
// banner_section end
export default BannerSection;
