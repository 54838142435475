import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  //  this is  tilte start
  useEffect(() => {
    document.title = "404 Error";
  });
  //  this is  tilte end
  return (
    <>
      {/* start error section */}
      <div className="grid h-[55vh] sm:h-[65vh] md:h-[75vh] lg:h-[90vh] xl:h-[100vh] place-content-center bg-black xl:pb-[15rem] ">
        <div className="text-center xl:pb-12">
          <strong className="text-9xl font-black text-red-600 lg:text-[12rem] ">
            404!
          </strong>

          <h1 className="text-2xl font-bold tracking-tight text-slate-200 dark:text-white sm:text-4xl">
            Uh-oh!
          </h1>

          <p className="mt-4 text-slate-300 dark:text-gray-400">
            The page you were looking for could not be found.
          </p>

          <Link
            to="/"
            className="mt-6 inline-block rounded bg-pink-700 px-5 py-3 text-sm font-medium text-white hover:bg-black/30 border-2 border-pink-700  md:px-8 md:py-4 xl:px-12 xl:py-5 transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-100 duration-100"
          >
            Go Back Home
          </Link>
        </div>
      </div>
      {/* end error section */}
    </>
  );
};

export default Error404;
