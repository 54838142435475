import React from "react";
import PropTypes from "prop-types";

const Service = (props) => {
  return (
    <>
      {/* service grid start */}
      <section className="bg-black">
        <div className="py-8">
          <h1 className="text-3xl 2xl:text-6xl sm:text-5xl md:text-5xl lg:text-6xl font-bold text-white mb-3 text-center ">
            {props.title}
          </h1>
          <br />
          <h4 className="text-lg text-gray-100 text-center px-20">
            {/* use props description1*/}
            {props.description1}

            <p className="text-lg text-gray-100 text-center">
              {/* use props description1*/}
              {props.description2}
            </p>
          </h4>
        </div>
        <div className="container mx-auto py-14 px-4 sm:px-12 2xl:px-40">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* pas a card props */}
            {props.card}
          </div>
        </div>
      </section>
    </>
    //  service grid end
  );
};
Service.propTypes = {
  title: PropTypes.string,
  description1: PropTypes.string,
  description2: PropTypes.string,
  card: PropTypes.any,
};
export default Service;
