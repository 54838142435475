import React from "react";
import PropTypes from "prop-types";

const LittleBanner = (props) => {
  return (
    <>
      {/* LittelBanner start */}
      <section className="relative w-full h-full py-36 lg:py-[10rem] 2xl:py-[12rem]">
        <div
          style={props.image}
          className="absolute inset-0 bg-cover bg-no-repeat bg-center"
        ></div>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Text content on top of the shadow */}
        <div className="relative text-center">
          <h1 className="text-4xl sm:text-5xl 2xl:text-7xl mb-4 font-extrabold text-white">
            {props.title}
          </h1>
          <p className="text-xl sm:text-2xl text-gray-100 px-20">
            {props.description}
          </p>
        </div>
      </section>

      {/* LittelBanner end */}
    </>
  );
};
// LittleBanner propTypes define
LittleBanner.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default LittleBanner;
