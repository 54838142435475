import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineCloseCircle, AiOutlineMenu } from "react-icons/ai";
import kitImage from "../../static/kit.png";

const Nevbar = () => {
  const [openmenu, setopenmenu] = useState(false);
  const hendelOpenmenu = () => {
    setopenmenu(!openmenu);
  };

  return (
    // Navbar start
    <>
      <div className="sticky top-0 z-40 flex w-full justify-between px-4 items-center bg-black text-white pt-2 pb-2 lg:px-36">
        <div className="flex title-font font-medium items-center text-black mb-4 md:mb-0">
          <Link to="/" className="flex items-center">
            <img
              className="h-8 w-8 object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
              //  use a props
              src={kitImage}
              alt="K.IT"
            />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"
              viewBox="0 0 24 24"
            >
              <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
            </svg> */}
            <span className="ml-3 text-xl text-white">
              KHANDAKER <span className="text-green-700">IT.</span>
            </span>
          </Link>
        </div>
        <div className="flex menubar select-none">
          <ul className="hidden lg:flex md:hidden ">
            <li className="p-4 block hover:text-pink-700">
              <Link to="/">Home</Link>
            </li>
            <li className="p-4  block hover:text-pink-700">
              <Link to="/Service">Service</Link>
            </li>
            <li className="p-4  block hover:text-pink-700">
              <Link to="/about">About</Link>
            </li>
          </ul>
          <Link to="/contact">
            <button
              className="hidden lg:block items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none
     hover:bg-pink-600 hover:text-white rounded text-base mt-4 xl:m-4"
            >
              CONTACT
            </button>
          </Link>

          <button>
            <AiOutlineMenu
              onClick={hendelOpenmenu}
              size={40}
              className=" mx-4 lg:hidden md:block sm:block p-2 border rounded"
            />
          </button>
        </div>
      </div>

      {/* medium and small device design start */}

      <div
        onClick={hendelOpenmenu}
        className={
          openmenu
            ? " w-1/2 px-2 fixed top-0 z-50 left-0 bg-black h-screen duration-700 ease-in"
            : " w-1/2 px-2 fixed top-0 left-[-100%] bg-black h-screen duration-700 ease-in"
        }
      >
        <button>
          <AiOutlineCloseCircle
            onClick={hendelOpenmenu}
            className="text-white m-5 "
            size={30}
          />
        </button>

        <ul className="text-white w-full py-5">
          <li
            onClick={hendelOpenmenu}
            className="py-2 p-4 w-full hover:text-pink-700"
          >
            <Link to="/"> Home </Link>
          </li>
          <li
            onClick={hendelOpenmenu}
            className="py-2 p-4 w-full hover:text-pink-700"
          >
            <Link to="/Service"> Service </Link>
          </li>
          <li
            onClick={hendelOpenmenu}
            className="py-2 p-4 w-full hover:text-pink-700"
          >
            <Link to="/about">About</Link>
          </li>
        </ul>
        <Link to="/contact">
          <button
            onClick={hendelOpenmenu}
            className="block lg:hidden w-full py-1 px-4 border rounded text-white hover:text-pink-700"
          >
            CONTACT
          </button>
        </Link>
      </div>
      {/* medium and small device design end */}
    </>
    // Navbar end
  );
};

export default Nevbar;
