import React from "react";
import PropTypes from "prop-types";

const ContactCard = (props) => {
  const isEmailType = props?.type === "email";

  return (
    <>
      {/* contact card start */}
      <div className="shadow-lg p-6 rounded-lg bg-gradient-to-r from-gray-800 to-gray-900 hover:bg-gradient-to-l transition duration-300 ease-in-out">
        <div className="flex items-center justify-center mb-4">
          <div className="rounded-full p-3 bg-gradient-to-r from-red-500 to-red-600 shadow-lg">
            {/* use a props */}
            {props.icon}
          </div>
        </div>
        <div className="text-center">
          <h1 className="text-xl font-bold text-white mb-2">
            {/* use a props */}
            {props.title}
          </h1>
          {isEmailType ? (
            <>
              <a
                href={`mailto:${props.description1}`}
                className="text-gray-300 mb-1 block"
              >
                {props.description1}
              </a>
              <a
                href={`mailto:${props.description2}`}
                className="text-gray-300 block"
              >
                {props.description2}
              </a>
            </>
          ) : (
            <>
              <p className="text-gray-300 mb-1">{props.description1}</p>
              <p className="text-gray-300">{props.description2}</p>
            </>
          )}
        </div>
      </div>
      {/* contact card end */}
    </>
  );
};

// define prop types
ContactCard.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  type: PropTypes.string,
  description1: PropTypes.string,
  description2: PropTypes.string,
};

export default ContactCard;
