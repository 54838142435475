import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "../../../Component/navbar_section/Nevbar";
import Footer from "../../../Component/footer_section/Footer";
import bannerimage from "../../../static/contactbanner_image/servicedetails.jpg";
import LittleBanner from "../../../Component/little_banner/LittleBanner";
import ServiceDetails from "../../../Component/service_details_section/ServiceDetails";

const ServiceDetailsPage = () => {
  const params = useParams();

  const [service, setService] = useState();

  const getApiData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SECRETE_URL}service/${params.id}`
      );
      const x = (await result).data;
      setService(x);
    } catch (error) {
      console.error(error);
    }
  };

  // set title start
  useEffect(() => {
    document.title = "ServiceDetails";
    getApiData();
  });
  // set title end

  return (
    <>
      <Navbar />
      {/* LittleBanner start */}
      <LittleBanner
        image={{ backgroundImage: `url(${bannerimage})` }}
        title="Service Details"
        description="Explore our Service Details, where each offering is crafted with care and precision. We focus on delivering fresh, innovative solutions tailored to fit your exact needs, ensuring quality and satisfaction in every project."
      />
      {/* LittleBanner end */}

      {/* ServiceDetails start */}
      <ServiceDetails title={service?.name} description={service?.details} />
      {/* ServiceDetails end */}
      <Footer />
    </>
  );
};

export default ServiceDetailsPage;
