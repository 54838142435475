import React from "react";
import image from "../../static/find_image/finding-us-01.png";

const Find = () => {
  return (
    <>
      {/* Find Start */}
      <section className="" style={{ backgroundColor: "#18161b" }}>
        <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8 ">
          <div className="grid grid-cols-1 lg:h-[34rem] xl:h-[39rem] lg:grid-cols-2">
            <div className="relative z-10 lg:py-16 ">
              <div className="relative h-64 sm:h-80 lg:h-full">
                <img
                  alt="House"
                  // use image
                  src={image}
                  className="absolute inset-0 h-full w-full object-cover rounded-xl"
                />
              </div>
            </div>

            <div className="relative flex items-center bg-gray-400 ">
              <span className="hidden lg:absolute lg:inset-y-0 lg:-left-16 lg:block lg:w-16 lg:bg-gray-400  "></span>

              <div className="p-8 sm:p-16 lg:p-24">
                <h2 className="text-2xl font-bold sm:text-3xl text-red-500">
                  Discover Top Talent for Your Projects
                </h2>

                <p className="mt-4 text-gray-100">
                  Unlock the potential of your projects with our platform,
                  connecting you to a diverse pool of skilled professionals
                  ready to bring your vision to life. Our service is designed to
                  match your project needs with the ideal candidates, ensuring
                  quality, efficiency, and growth. Step into a world where
                  finding the perfect fit for your project is streamlined and
                  stress-free.
                </p>

                <a
                  target="_"
                  href="https://forms.gle/55SxXhf14ivimpGL7"
                  className="mt-8 inline-block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
                >
                  <button>GET STARTED</button>
                </a>

                <p className="mt-4 text-gray-100">
                  Explore our form to start connecting with leading
                  professionals and take your projects to the next level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Find Start */}
    </>
  );
};

export default Find;
