import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home/Home";
import ServicePage from "./service_page/ServicePage";
import AboutPage from "./about_page/AboutPage";
import ContactPage from "./contact_page/ContactPage";
import ServiceDetailsPage from "./service_page/id";
import Error404 from "./Error404";
import Scroll from "../Component/scroll_restoration/Scroll";

function App() {
  return (
    <>
      {/* router start */}
      <Router>
        <Scroll />
        <Routes>
          <Route exect path="/" element={<Home />} />
          <Route exect path="/service" element={<ServicePage />} />
          <Route exect path="/about" element={<AboutPage />} />
          <Route exect path="/contact" element={<ContactPage />} />
          <Route exect path="/service/:id" element={<ServiceDetailsPage />} />
          <Route exect path="*" element={<Error404 />} />
        </Routes>
      </Router>
      {/* router end */}
    </>
  );
}

export default App;
