import React from "react";
import propTypes from "prop-types";

const Video = (props) => {
  return (
    <>
      {/* start video section */}
      <section className="bg-black">
        <div
          className="embed-responsive embed-responsive-16by9 relative w-full overflow-hidden"
          style={{ paddingTop: "45.25%" }}
        >
          <iframe
            className="embed-responsive-item absolute top-0 right-0 bottom-0 left-0 w-full h-full py-3 px-8 sm:px-18 sm:py-2 md:h-full md:py-1 lg:h-full lg:w-[100%] lg:px-[3rem] xl:px-82 xl:py-18 xl:h-full 2xl:h-full 2xl:w-full 2xl:px-[170px] 2xl:py-16 "
            title="youtube video"
            // Video Source Props start
            src={props.youtubeVideo}
            // Video Source Props end
          ></iframe>
        </div>
      </section>
      {/* end video section */}
    </>
  );
};
// video source props type start
Video.propTypes = {
  youtubeVideo: propTypes.any,
};
// video source props type end
export default Video;
