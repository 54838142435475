import React, { useMemo, useEffect, useState } from "react";
import axios from "axios";
import {
  AiTwotoneAppstore,
  AiOutlineUsergroupAdd,
  AiOutlineDesktop,
} from "react-icons/ai";
import { BsLayersFill, BsCodeSlash, BsRssFill } from "react-icons/bs";
import { SiAntdesign, SiMaterialdesign, SiWebflow } from "react-icons/si";
import { MdWebAssetOff, MdWeb, MdOutlineAppSettingsAlt } from "react-icons/md";
import {
  TiSocialInstagram,
  TiSocialGithubCircular,
  TiSocialDribbbleCircular,
} from "react-icons/ti";
import { stringCutter } from "../../const/reuseable_func";
import { Link } from "react-router-dom";
import BannerSection from "../../Component/banner_section/BannerSection";
import ProgressBar from "../../Component/progress_section/ProgressBar";
import Service from "../../Component/service_section/Service";
import VideoSource from "../../Component/video_section/VideoSource";
import Brand from "../../Component/brand_section/Brand";
import Card from "../../Component/service_section/Card";
import Navbar from "../../Component/navbar_section/Nevbar";
import Footer from "../../Component/footer_section/Footer";
import Error500 from "../Error500";

const designIcon = [
  <AiTwotoneAppstore key={1} />,
  <SiAntdesign key={2} />,
  <SiMaterialdesign key={3} />,
];

const webIcon = [
  <MdWeb key={1} />,
  <SiWebflow key={2} />,
  <MdWebAssetOff key={3} />,
];

const appIcon = [
  <MdOutlineAppSettingsAlt key={1} />,
  <BsLayersFill key={2} />,
  <BsCodeSlash key={3} />,
];

const digitalMarketingIcon = [
  <TiSocialDribbbleCircular key={1} />,
  <TiSocialGithubCircular key={2} />,
  <TiSocialInstagram key={3} />,
];

const otherServiceIcon = [
  <AiOutlineUsergroupAdd key={1} />,
  <AiOutlineDesktop key={2} />,
  <BsRssFill key={3} />,
];

const Home = () => {
  const [whatWeDo, setWhatWeDo] = useState([]);
  const [Progress, setProgress] = useState([]);
  const [isError, setIsError] = useState(false);
  const [services, setServices] = useState([]);
  // connect api
  const getApiData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SECRETE_URL}what-we-do/`
      );
      setWhatWeDo(result.data.whatWeDo[0]);
      setProgress(result.data.Progress);
    } catch (error) {
      setIsError(true);
    }
  };

  const getApiDatas = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_SECRETE_URL}services/`);
      setServices(result.data);
      result();
    } catch (error) {}
  };
  useEffect(() => {
    getApiData();
    getApiDatas();
  }, []);

  //  this is  tilte start
  useEffect(() => {
    document.title = "Home";
  });

  const data = useMemo(() => {
    const design = [];
    const webAndApp = [];
    const ai = [];
    const digitalMarketing = [];
    const otherService = [];

    // console.log("services______:", services);

    services?.forEach((service) => {
      switch (String(service.tag).toUpperCase()) {
        case "AI":
          ai.push(service);
          break;

        case "WEBANDAPP":
          webAndApp.push(service);
          break;

        case "DESIGN":
          design.push(service);
          break;

        case "DIGITALMARKETING":
          digitalMarketing.push(service);
          break;

        default:
          otherService.push(service);
          break;
      }
    });

    return {
      design: design,
      webAndApp: webAndApp,
      ai: ai,
      digitalMarketing: digitalMarketing,
      otherService: otherService,
    };
  }, [services]);

  const design = useMemo(
    () =>
      data.design.map((service, index) => (
        <Link key={index} to={`/service/${service.id}`}>
          <Card
            key={1}
            icon={designIcon[index] ? designIcon[index] : designIcon[0]}
            title={stringCutter({ text: service.name })}
            description={stringCutter({ text: service.details })}
          />
        </Link>
      )),

    [data.design]
  );

  const webAndApp = useMemo(
    () =>
      data.webAndApp.map((service, index) => (
        <Link key={index} to={`/service/${service.id}`}>
          <Card
            key={index}
            icon={webIcon[index] ? webIcon[index] : webIcon[0]}
            title={stringCutter({ text: service.name })}
            description={stringCutter({ text: service.details })}
          />
        </Link>
      )),

    [data.webAndApp]
  );

  const ai = useMemo(
    () =>
      data.ai.map((service, index) => (
        <Link key={index} to={`/service/${service.id}`}>
          <Card
            key={index}
            icon={appIcon[index] ? appIcon[index] : appIcon[0]}
            title={stringCutter({ text: service.name })}
            description={stringCutter({ text: service.details })}
          />
        </Link>
      )),

    [data.ai]
  );

  const digitalMarketing = useMemo(
    () =>
      data.digitalMarketing.map((service, index) => (
        <Link key={index} to={`/service/${service.id}`}>
          <Card
            key={index}
            icon={
              digitalMarketingIcon[index]
                ? digitalMarketingIcon[index]
                : digitalMarketingIcon[0]
            }
            title={stringCutter({ text: service.name })}
            description={stringCutter({ text: service.details })}
          />
        </Link>
      )),

    [data.digitalMarketing]
  );

  const otherService = useMemo(
    () =>
      data.otherService.map((service, index) => (
        <Link key={index} to={`/service/${service.id}`}>
          <Card
            key={index}
            icon={
              otherServiceIcon[index]
                ? otherServiceIcon[index]
                : otherServiceIcon[0]
            }
            title={stringCutter({ text: service.name, textLen: 30 })}
            description={stringCutter({ text: service.details, textLen: 60 })}
          />
        </Link>
      )),

    [data.otherService]
  );

  return (
    <>
      {isError ? (
        <div>
          <Error500 />
        </div>
      ) : (
        <div>
          <Navbar />
          {/* this is a banner_section  */}
          <BannerSection />
          {/* this is progressbar_section */}
          <ProgressBar whatWeDoData={whatWeDo} ProgressData={Progress} />

          {/* this is service_section and use props*/}
          <Service
            // service props start
            title="AI Services"
            description1="Unlock the potential of AI with our bespoke services, designed to drive innovation and efficiency.Harnessing the latest in machine learning and data analytics, we tailor solutions that transform challenges into opportunities."
            description2="Empower your business with intelligent systems that learn and adapt. From automation to predictive analysis, our AI services redefine what's possible, delivering impactful results and a competitive edge."
            // service props end

            // card props start
            card={ai}
            // card props end
          />

          <Service
            // service props start
            title="Web And APP Services"
            description1="Crafting digital masterpieces, our Web & App Service offers custom solutions that blend innovative design with robust functionality. Elevate your online presence with platforms that engage and inspire your audience."
            description2="From concept to launch, we prioritize seamless user experience and cutting-edge technology to create websites and apps that not only look great but also perform flawlessly. Let us transform your digital vision into reality."
            // service props end

            // card props start
            card={webAndApp}
            // card props end
          />

          <Service
            // service props start
            title="Design Services"
            description1="Elevate your brand with our tailor-made design services. From intuitive UI/UX to stunning visuals, we transform ideas into artistry that captivates and engages."
            description2="Make your brand unforgettable. Our designs merge innovation with elegance, ensuring your visuals not only attract but also connect deeply with your audience."
            // service props end

            // card props start
            card={design}
            // card props end
          />

          <Service
            // service props start
            title="DigitalMarketing Services"
            description1="Revolutionize your online presence with our Digital Marketing Service. Tailored to your unique brand, we harness the power of SEO, social media, and content marketing to amplify your voice and connect with your target audience."
            description2="Experience transformative growth as we navigate the digital landscape with expertise and creativity. Our strategic approach ensures your brand not only stands out but also achieves sustainable success in the ever-evolving online world."
            // service props end

            // card props start
            card={digitalMarketing}
            // card props end
          />

          <Service
            // service props start
            title="Other Service"
            description1="Discover the spectrum of possibilities with our Other Services, designed to meet the diverse needs of your business. From emerging technologies to niche market solutions, "
            description2="we offer customized support that goes beyond the conventional, ensuring every aspect of your operations is enhanced and future-proof."
            // service props end

            // card props start
            card={otherService}
            // card props end
          />
          {/* this is video_section */}
          <VideoSource />
          {/* this is brand_section */}
          <Brand />
          <Footer />
        </div>
      )}
    </>
  );
};

export default Home;
