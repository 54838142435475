import React from "react";
import Video from "./Video";

const VideoSource = () => {
  return (
    <>
      {/* Video Source Section start */}
      <Video youtubeVideo="https://www.youtube.com/embed/HK6y8DAPN_0" />
      {/* Video Source Section end */}
    </>
  );
};

export default VideoSource;
