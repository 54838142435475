import { React, useMemo } from "react";
import { BsFacebook, BsLinkedin, BsTwitter } from "react-icons/bs";
import pic1 from "../../static/skill_image/team-01.jpg";
import pic2 from "../../static/skill_image/team-02.jpg";
import pic3 from "../../static/skill_image/team-03.jpg";
import Skillcard from "./SkillCard";

const Skill = () => {
  const card = useMemo(
    () => [
      <Skillcard
        key="1"
        image={pic1}
        icon1={<BsFacebook />}
        icon2={<BsLinkedin />}
        icon3={<BsTwitter />}
        title="Nobin Khandaker"
        description="Senior Software Developer"
      />,
      <Skillcard
        key="2"
        image={pic2}
        icon1={<BsFacebook />}
        icon2={<BsLinkedin />}
        icon3={<BsTwitter />}
        title="Rezoan Ashik"
        description="HR & Tester"
      />,
      <Skillcard
        key="3"
        image={pic3}
        icon1={<BsFacebook />}
        icon2={<BsLinkedin />}
        icon3={<BsTwitter />}
        title="Nur Khandaker"
        description=" Jr. Web Developer."
      />,
    ],
    []
  );

  return (
    <>
      {/* start skill  */}
      <section>
        <div className="" style={{ backgroundColor: "#18161b" }}>
          <h1 className="text-center text-3xl font-bold pt-4 lg:text-5xl text-white ">
            Skilled Team
          </h1>
          <p className="text-center font-bold pt-4 text-gray-400 px-20">
            Our team is our greatest asset, comprising seasoned professionals
            with extensive experience in web, mobile, and desktop application
            development, as well as AI and machine learning. Each member brings
            a unique set of skills and creativity, ensuring innovative solutions
            and outstanding results for every project. We pride ourselves on our
            collaborative approach, dedication to excellence, and ability to
            transform complex challenges into seamless solutions.
          </p>
        </div>

        <div
          className="flex min-h-screen lg:min-h-[68vh] xl:min-h-[72vh] items-center justify-center "
          style={{ backgroundColor: "#18161b" }}
        >
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3 pt-4 pb-4">
            {/* aplly in usememo */}
            {card}
          </div>
        </div>
      </section>
      {/* end  skill  */}
    </>
  );
};

export default Skill;
