import React from "react";
import PropTypes from "prop-types";
import image1 from "../../static/service-01.png";
import VideoSource from "../../Component/video_section/VideoSource";

const ServiceDetails = (props) => {
  return (
    <>
      {/* service details start */}
      <section className="text-gray-600 body-font bg-black ">
        <div className="container mx-auto flex px-12 2xl:px-24 py-24 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={image1}
            />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left text-center 2xl:px-24 text-white">
            <div>
              <h1 className="font-bolt text-2xl mb-3 text-justify">
                {props.title}
              </h1>
              <p className="title-font sm:text-1xl mb-4 text-justify ">
                {props.description}
              </p>
            </div>
          </div>
        </div>
        <div>
          <VideoSource />
        </div>
      </section>
      {/*service details end */}
    </>
  );
};

ServiceDetails.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ServiceDetails;
