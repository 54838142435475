import React from "react";
import propTypes from "prop-types";

const BrandProps = (props) => {
  return (
    //BrandProps start
    <>
      <a
        target="_"
        href={props.targetUrl}
        className="w-[120px] 2xl:w-[150px] py-5 flex items-center justify-center mx-5"
      >
        <img
          // use props img logo
          src={props.logo}
          alt="404"
          className="w-16 h-16 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-100"
        />
      </a>
    </>

    // Brand Props end
  );
};
//  props type start
BrandProps.propTypes = {
  logo: propTypes.any,
  targetUrl: propTypes.string,
};
// props type end
export default BrandProps;
