import React from "react";
import CountUp from "react-countup";

const Count = () => {
  return (
    <>
      {/* count Section Start */}
      <section
        className="text-gray-600 body-font"
        style={{ backgroundColor: "#18161b" }}
      >
        <div>
          <h1 className="text-center text-3xl font-bold pt-4 lg:text-5xl text-white">
            Our Expertise & Achievements
          </h1>
        </div>
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4 text-center">
            {/* Web Development  */}
            <div className="p-4 sm:w-1/4 w-1/2">
              <h2 className="title-font font-bold sm:text-4xl text-3xl lg:text-6xl text-red-500 mb-3">
                <CountUp end={50} duration={2.75} suffix="+" />
              </h2>
              <p className="leading-relaxed text-gray-400">
                Cutting-edge web applications tailored to business needs.
              </p>
            </div>

            {/* Mobile App Development  */}
            <div className="p-4 sm:w-1/4 w-1/2">
              <h2 className="title-font font-bold sm:text-4xl text-3xl lg:text-6xl text-red-500 mb-3">
                <CountUp end={1} duration={2.75} suffix="+" />
              </h2>
              <p className="leading-relaxed text-gray-400">
                Innovative mobile solutions across Android and iOS platforms.
              </p>
            </div>

            {/* Desktop App Development  */}
            <div className="p-4 sm:w-1/4 w-1/2">
              <h2 className="title-font font-bold sm:text-4xl text-3xl lg:text-6xl text-red-500 mb-3">
                <CountUp end={1} duration={2.75} suffix="+" />
              </h2>
              <p className="leading-relaxed text-gray-400">
                Robust desktop applications for comprehensive business
                solutions.
              </p>
            </div>

            {/* AI and Robotics */}
            <div className="p-4 sm:w-1/4 w-1/2">
              <h2 className="title-font sm:text-4xl text-3xl font-bold lg:text-6xl text-red-500 mb-3">
                <CountUp end={10} duration={2.75} suffix="+" />
              </h2>
              <p className="leading-relaxed text-gray-400">
                Advanced AI and robotics innovations driving the future.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* count Section end */}
    </>
  );
};

export default Count;
