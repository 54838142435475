import React, { useCallback } from "react";
import aboutImage from "../../static/about.png";

const ProgressBar = (Props) => {
  const progressStyle = useCallback((percentage) => {
    if (percentage < 29) {
      return "bg-gradient-to-r to-blue-600 from-red-700 hover:from-red-500 hover:to-pink-600 h-3.5";
    } else if (percentage < 49) {
      return "bg-gradient-to-r to-red-500 from-cyan-700 hover:from-green-600 hover:to-red-900 h-3.5";
    } else if (percentage < 69) {
      return "bg-gradient-to-r to-amber-600 from-fuchsia-800 hover:from-blue-800 hover:to-green-800 h-3.5";
    } else if (percentage < 79) {
      return "bg-gradient-to-r to-green-800 from-green-400 hover:from-red-400 hover:to-yellow-800 h-3.5";
    } else {
      return "bg-gradient-to-r to-green-800 from-green-400 hover:from-red-400 hover:to-yellow-800 h-3.5";
    }
  }, []);

  return (
    <>
      <section className="mb-0 text-gray-800 bg-black">
        <div className="px-6 py-12 md:px-12 text-center lg:text-left">
          <div className="container mx-auto px-8">
            <div className="grid lg:grid-cols-2 gap-12 2xl:px-12 items-center">
              <div className="mt-12 lg:mt-0 xl:px-16">
                <h1 className="text-white text-3xl md:text-6xl sm:text-4xl lg:text-5xl xl:text-6xl font-bold tracking-tight text-justify mb-12 2xl:mb-24">
                  {/* use what we do title props */}
                  {Props.whatWeDoData.title}
                </h1>
                <h3 className=" text-white text-sm sm:text-lg lg:text-lg 2xl:pb-8 2xl:text-xl text-justify">
                  {/* use what we do description props */}
                  {Props.whatWeDoData.description}
                </h3>
                <br />
                <h1 className="text-2xl font-bold text-white">
                  Our Working Process.
                </h1>
                <br />

                {/* progressbar start */}
                {Props.ProgressData.map((progress, id) => (
                  <div key={id}>
                    <div className="flex justify-between mb-1">
                      <span className="text-base font-medium text-white dark:text-white">
                        {progress.name}
                      </span>
                      <span className="text-sm font-medium text-white dark:text-white">
                        {progress.percentage}%
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 h-3.5 dark:bg-gray-700 ">
                      <div
                        className={progressStyle(progress.percentage)}
                        // inline_style_dynamic_width_value_pass
                        style={{
                          width: `${progress.percentage}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
                {/* progressbar end */}
              </div>
              <div className="mb-12 lg:mb-0 sm:px-4 md:px-2 lg:px-0 ">
                <img
                  className="object-cover object-center md:object-cover rounded 2xl:px-20 2xl:pb-16 2xl:pt-12 xl:px-16 w-full h-full"
                  alt="herosection"
                  src={aboutImage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProgressBar;
