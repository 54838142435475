import React from "react";

const Error500 = () => {
  return (
    <>
      <div className="grid h-[100vh] sm:h-[100vh] md:h-[100vh] xl:h-[100vh] bg-black xl:pb-[15rem]  ">
        <div className="py-32 px-8 sm:px-24 md:px-[12rem] xl:px-[20rem]">
          <h1 className=" text-2xl sm:text-3xl xl:text-5xl mb-8 font-bold text-red-600">
            500! Internal Server Error
          </h1>
          <div>
            <p className="text-white text-1xl xl:text-2xl mb-6">
              The server encountered an error and could not completet your
              request.
            </p>
          </div>
          <div>
            <p className="text-white text-1xl xl:text-2xl">
              There is a problem whit the resource you are looking for, and it
              cannot be displayed
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error500;
