import React from "react";
import BrandProps from "./BrandProps";
import pic1 from "../../static/brandpng/brand-01.png";
import pic2 from "../../static/brandpng/brand-02.png";
import pic3 from "../../static/brandpng/brand-03.png";
import pic4 from "../../static/brandpng/brand-04.png";
import pic5 from "../../static/brandpng/brand-05.png";
import pic6 from "../../static/brandpng/brand-06.png";

const Brand = () => {
  return (
    <>
      {/* Brand Start */}
      <section className="bg-black px-4">
        <div className="py-20 sm:py-[40px] sm:px-[1.9rem] md:py-[50px] md:px-[5rem] lg:py-[50px] lg:px-[5.5rem] xl:py-[50px] xl:px-[130px] 2xl:w-[100%] 2xl:h-[100%]">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4 ">
              <div className="flex flex-wrap justify-center items-center">
                <BrandProps logo={pic1} targetUrl={"https://www.amgoai.com"} />
                <BrandProps logo={pic2} targetUrl={"https://possglobal.com/"} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Brand end */}
    </>
  );
};

export default Brand;
